<template>
	<div>
		<h1>{{ title }}</h1>
	</div>
</template>

<script>
export default {
	metaInfo () {
		return {
			title: this.title
		}
	},

	data () {
		return {
			title: 'Карточки'
		}
	}
}
</script>
